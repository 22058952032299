
import { defineComponent, PropType } from "vue";

import { formatDateString } from "@/utils";

import { News } from "@/types/News";
import PVote from "@/components/PVote.vue";

export default defineComponent({
  name: "PNews",
  components: { PVote },
  props: {
    news: { type: Object as PropType<News>, required: true }
  },

  setup(props) {
    return { props, formatDateString };
  }
});
