import { reactive, toRefs } from "vue";
import { omit, isNull } from "lodash";
import { PageableNews } from "@/types/News";
import { newsService } from "@/services/news.service";

type NewsState = {
  news: PageableNews | null;
  loading: boolean;
  error: boolean;
};

const state = reactive<NewsState>({
  news: null,
  loading: false,
  error: false
});

export function useNews() {
  async function loadNews(
    userId: number | string,
    page?: number,
    size?: number
  ) {
    try {
      state.loading = true;
      state.error = false;

      state.news = null;

      state.news = await newsService.getNews(userId, { page, size });
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  }

  async function loadNextChunk(userId: number | string) {
    if (isNull(state.news)) return;
    if (state.news.last) return;

    try {
      state.loading = true;
      state.error = false;

      const page = state.news.number + 1;
      const size = 10;

      const response = await newsService.getNews(userId, { page, size });
      const paginate = omit(response, ["content"]);

      Object.assign(state.news, paginate);

      state.news.content.push(...response.content);
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  }

  async function updateVote(newsId: number, variants: Array<number>) {
    try {
      state.loading = true;
      state.error = false;

      const news = await newsService.vote(newsId, variants);

      const index = (state.news as PageableNews).content.findIndex(
        cont => cont.id === newsId
      );

      (state.news as PageableNews).content[index] = news;
    } catch {
      state.error = true;
    } finally {
      state.loading = false;
    }
  }

  return {
    ...toRefs(state),
    loadNews,
    loadNextChunk,
    updateVote
  };
}
