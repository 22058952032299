import qs from "qs";
import { AxiosInstance } from "axios";

import { api } from "@/services/api.service";
import { News, PageableNews, Vote } from "@/types/News";

class NewsService {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  public getNews(
    userId: number | string,
    {
      page = 0,
      size = 10
    }: {
      page: number | undefined;
      size: number | undefined;
    }
  ): Promise<PageableNews> {
    const query = qs.stringify({ page, size });
    return this.api.get(`/area/user/${userId}/news${query && "?" + query}`);
  }

  public vote(voteId: number, variants: Array<number>): Promise<News> {
    return this.api.post(`/area/news/${voteId}`, variants);
  }
}

export const newsService = new NewsService(api);
