
import { computed, defineComponent, PropType, ref } from "vue";
import { Vote, VoteVariant } from "@/types/News";
import { formatDateString } from "@/utils";
import PVoteVariant from "@/components/PVoteVariant.vue";
import { newsService } from "@/services/news.service";
import { useNews } from "@/store/news.store";
import { useRoute } from "vue-router";
import MButton from "@/components/ui/mobile/buttons/MButton.vue";

export default defineComponent({
  name: "PVote",
  components: { MButton, PVoteVariant },
  props: {
    newsId: { type: Number, required: true },
    vote: { type: Object as PropType<Vote>, required: true }
  },

  setup(props) {
    const newsStore = useNews();
    const selected = ref<Array<number>>([]);

    const maxPercentage = computed(() => {
      const percentMap = props.vote.variants.map(v => v.percent);
      return Math.max(...percentMap);
    });

    const toggle = (variant: VoteVariant) => {
      if (props.vote.voted) return;

      const include = isSelected(variant);

      include
        ? selected.value.splice(selected.value.indexOf(variant.id), 1)
        : props.vote.multiple
        ? selected.value.push(variant.id)
        : (selected.value = [variant.id]);
    };

    const isSelected = (variant: VoteVariant) => {
      return selected.value.includes(variant.id) || variant.selected;
    };

    const updateVote = async () => {
      await newsStore.updateVote(props.newsId, selected.value);
    };

    return {
      props,
      formatDateString,
      isSelected,
      toggle,
      selected,
      updateVote,
      maxPercentage
    };
  }
});
