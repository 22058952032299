
import { computed, defineComponent, PropType } from "vue";
import { VoteVariant } from "@/types/News";

export default defineComponent({
  name: "PVoteVariant",
  props: {
    variant: { type: Object as PropType<VoteVariant> },
    stats: { type: Boolean },
    selected: { type: Boolean },
    maxPercentage: { type: Number, default: 0 }
  }
});
