
import { defineComponent, PropType } from "vue";

import PCard from "@/components/PCard.vue";
import PNotAvailable from "@/components/PNotAvailable.vue";
import { useNews } from "@/store/news.store";
import PNews from "@/components/PNews.vue";
import { RouteRecordRaw, useRoute } from "vue-router";
import MButton from "@/components/ui/mobile/buttons/MButton.vue";

export default defineComponent({
  name: "PNewspaper",
  components: { MButton, PNews, PNotAvailable, PCard },
  props: {
    to: { type: Object as PropType<Partial<RouteRecordRaw>> },
    flat: { type: Boolean },
    grid: { type: Boolean }
  },

  setup(props) {
    const news = useNews();
    const route = useRoute();

    return {
      props,
      route,
      news: news.news,
      loadNextChunk: news.loadNextChunk,
      loading: news.loading
    };
  }
});
